export default [
  [15,15,15],
  [15,15,14],
  [15,14,15],
  [14,15,15],
  [15,15,13],
  [15,14,14],
  [13,15,15],
  [15,13,15],
  [14,14,15],
  [14,15,14],
  [13,14,15],
  [14,13,15],
  [15,15,12],
  [15,13,14],
  [14,14,14],
  [12,15,15],
  [14,15,13],
  [15,14,13],
  [15,12,15],
  [13,15,14],
  [15,11,15],
  [15,15,11],
  [15,12,14],
  [14,15,12],
  [15,13,13],
  [14,14,13],
  [14,13,14],
  [14,12,15],
  [11,15,15],
  [12,14,15],
  [15,14,12],
  [13,15,13],
  [13,14,14],
  [12,15,14],
  [13,13,15],
  [11,14,15],
  [15,12,13],
  [14,12,14],
  [14,15,11],
  [14,11,15],
  [12,14,14],
  [15,13,12],
  [15,14,11],
  [15,10,15],
  [11,15,14],
  [13,15,12],
  [12,15,13],
  [15,15,10],
  [14,14,12],
  [13,14,13],
  [15,11,14],
  [14,13,13],
  [13,13,14],
  [12,13,15],
  [10,15,15],
  [13,12,15],
  [14,10,15],
  [14,14,11],
  [12,13,14],
  [10,15,14],
  [15,14,10],
  [15,10,14],
  [13,15,11],
  [14,13,12],
  [12,15,12],
  [14,15,10],
  [11,15,13],
  [15,11,13],
  [14,12,13],
  [13,14,12],
  [12,12,15],
  [15,13,11],
  [14,11,14],
  [13,13,13],
  [15,12,12],
  [12,14,13],
  [10,14,15],
  [13,12,14],
  [11,14,14],
  [13,11,15],
  [11,13,15],
  [10,14,14],
  [11,12,15],
  [13,10,15],
  [13,11,14],
  [11,14,13],
  [13,12,13],
  [13,13,12],
  [13,14,11],
  [13,15,10],
  [15,12,11],
  [12,15,11],
  [10,15,13],
  [14,10,14],
  [11,13,14],
  [12,14,12],
  [14,11,13],
  [14,12,12],
  [14,13,11],
  [14,14,10],
  [12,13,13],
  [11,15,12],
  [15,13,10],
  [12,12,14],
  [10,13,15],
  [15,10,13],
  [15,11,12],
  [12,11,15],
  [14,13,10],
  [13,13,11],
  [14,11,12],
  [10,12,15],
  [12,14,11],
  [12,15,10],
  [15,12,10],
  [13,12,12],
  [11,11,15],
  [11,12,14],
  [15,11,11],
  [14,10,13],
  [13,11,13],
  [10,15,12],
  [11,13,13],
  [12,13,12],
  [11,15,11],
  [14,12,11],
  [13,14,10],
  [13,10,14],
  [12,12,13],
  [10,14,13],
  [11,14,12],
  [15,10,12],
  [10,13,14],
  [12,11,14],
  [12,10,15],
  [11,13,12],
  [13,13,10],
  [12,10,14],
  [10,11,15],
  [10,12,14],
  [12,12,12],
  [12,13,11],
  [11,15,10],
  [12,14,10],
  [15,11,10],
  [11,14,11],
  [13,10,13],
  [10,13,13],
  [12,11,13],
  [15,10,11],
  [13,11,12],
  [11,12,13],
  [13,12,11],
  [11,11,14],
  [10,14,12],
  [14,12,10],
  [14,11,11],
  [11,10,15],
  [10,15,11],
  [14,10,12],
  [11,10,14],
  [12,11,12],
  [10,12,13],
  [13,12,10],
  [10,10,15],
  [11,12,12],
  [15,10,10],
  [10,14,11],
  [10,13,12],
  [13,10,12],
  [11,11,13],
  [12,12,11],
  [11,14,10],
  [12,10,13],
  [12,13,10],
  [14,11,10],
  [10,15,10],
  [13,11,11],
  [10,11,14],
  [14,10,11],
  [11,13,11],
  [11,13,10],
  [14,10,10],
  [12,11,11],
  [10,10,14],
  [11,11,12],
  [10,14,10],
  [11,12,11],
  [11,10,13],
  [13,11,10],
  [13,10,11],
  [10,13,11],
  [12,12,10],
  [10,12,12],
  [10,11,13],
  [12,10,12],
  [10,10,13],
  [10,13,10],
  [12,11,10],
  [11,10,12],
  [10,11,12],
  [11,11,11],
  [10,12,11],
  [13,10,10],
  [12,10,11],
  [11,12,10],
  [10,12,10],
  [12,10,10],
  [10,11,11],
  [11,11,10],
  [11,10,11],
  [10,10,12],
  [10,11,10],
  [11,10,10],
  [10,10,11],
  [10,10,10]
];

import React from "react"
import chargedMoves from "../../utils/charged_moves"
import fastMoves from "../../utils/fast_moves"
import { colors } from "../../utils/stats"

export default ({ pokemon }) => (
  <>
    <h2>{pokemon.name} moveset</h2>
    <p>
      {pokemon.name} has{" "}
      {pokemon.fastMoves.length + pokemon.chargedMoves.length} moves (
      {pokemon.fastMoves.length} fast moves and {pokemon.chargedMoves.length}{" "}
      charged moves).
    </p>

    <p>
      Those attacks are the ones <b>currently available</b>. Which means the{" "}
      <b>{pokemon.name}</b> you capture in the wild, after raid, during events,
      from an evolution or an egg, will have one of those moves. However, you
      can also change of moveset with <b>Fast TM</b> or <b>Charged TM</b>. Also,
      the moveset pool can change during specific events or with the Pokémon's
      form. Some movesets are gone and are called <b>legacy moves</b>. In
      consequence, some pokemons have legacy movesets that you can not have
      currently anymore.
    </p>

    <p>Here is a description of {pokemon.name} current moves:</p>

    <h3>{pokemon.name} fast moves</h3>
    <div>
      {pokemon.fastMoves.map(move => {
        const t_fm = fastMoves.find(fm => move === fm.name)
        return (
          <div key={move} style={{ marginBottom: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <h4 style={{marginTop: 0}}>
                  {t_fm.name}
                </h4>
                <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div>
                <div>{t_fm.power} damage</div>
              </div>
              <div
                style={{
                  backgroundColor: colors[t_fm.type.toLowerCase()],
                  color: "#ffffff",
                  fontWeight: "bold",
                  borderRadius: 8,
                  padding: "0 8px",
                }}
              >
                {t_fm.type}
              </div>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Energy loss: {t_fm.energy_delta}</div>
                <div>Duration: {t_fm.duration}ms</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div />
                <div>Stamina loss scaler: {t_fm.stamina_loss_scaler}</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>

    <h3>{pokemon.name} charged moves</h3>
    <div>
      {pokemon.chargedMoves.map(move => {
        const t_fm = chargedMoves.find(fm => move === fm.name)
        return (
          <div key={move} style={{ marginBottom: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <h4
                  style={{
                    margin: 0,
                    fontFamily: "inherit",
                    lineHeight: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  {t_fm.name}
                </h4>
                <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div>
                <div>{t_fm.power} damage</div>
              </div>
              <div
                style={{
                  backgroundColor: colors[t_fm.type.toLowerCase()],
                  color: "#ffffff",
                  fontWeight: "bold",
                  borderRadius: 8,
                  padding: "0 8px",
                }}
              >
                {t_fm.type}
              </div>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Critical hit chance: {t_fm.critical_chance}%</div>
                <div>Duration: {t_fm.duration}ms</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Energy loss: {t_fm.energy_delta}</div>
                <div>Stamina loss scaler: {t_fm.stamina_loss_scaler}</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </>
)

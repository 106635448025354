import React from "react"

import ivs from "../../utils/ivs"
import { getCP } from "../../utils/cpTable"
import { getWeather } from "../../utils/stats"

const Row = ({ atk, def, sta, pokemon, weatherIcons }) => (
  <div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ display: "flex" }}>
          <span style={{ fontWeight: "bold" }}>
            {getCP(pokemon, atk, def, sta)}
          </span>
          <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
          <span style={{ display: "flex" }}>
            {" "}
            (
            {weatherIcons.map(w => (
              <span key={w} style={{ display: "flex" }}>
                {w}
              </span>
            ))}{" "}
            {getCP(pokemon, atk, def, sta, 25)})
          </span>
        </div>
      </div>
      <div style={{ flex: 2, textAlign: "center" }}>{atk}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{def}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{sta}</div>
      <div style={{ flex: 1, textAlign: "right" }}>
        {Math.round((atk + def + sta) / 0.45)}%
      </div>
    </div>
  </div>
)

export default ({ pokemon }) => {
  const [cps, setCps] = React.useState([])
  const [weatherIcons, setWeatherIcons] = React.useState([])

  React.useLayoutEffect(() => {
    setCps(
      ivs
        .map(iv => [iv, getCP(pokemon, iv[0], iv[1], iv[2])])
        .sort((a, b) => b[1] - a[1])
    )
    setWeatherIcons(
      getWeather(
        pokemon.type1.toLowerCase(),
        pokemon.type2 && pokemon.type2.toLowerCase()
      )
    )
  }, [pokemon])

  return (
    <div>
      <h2>
        {pokemon.name} {"Possible CPs"}
      </h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontWeight: "bold", flex: 1 }}>CP</div>
        <div style={{ fontWeight: "bold", flex: 2, textAlign: "center" }}>
          {"ATK"}
        </div>
        <div style={{ fontWeight: "bold", flex: 2, textAlign: "center" }}>
          {"DEF"}
        </div>
        <div style={{ fontWeight: "bold", flex: 2, textAlign: "center" }}>
          {"STA"}
        </div>
        <div style={{ fontWeight: "bold", flex: 1, textAlign: "right" }}>
          {"%"}
        </div>
      </div>
      <div>
        {cps.map(iv => (
          <Row
            key={`${iv[0][0]}-${iv[0][1]}-${iv[0][2]}`}
            atk={iv[0][0]}
            def={iv[0][1]}
            sta={iv[0][2]}
            pokemon={pokemon}
            cp={iv[1]}
            weatherIcons={weatherIcons}
          />
        ))}
      </div>
    </div>
  )
}

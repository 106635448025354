const cpmultiplier = [
  {level: 1, multiplier: 0.094},
  {level: 1.5, multiplier: 0.135137432},
  {level: 2, multiplier: 0.16639787},
  {level: 2.5, multiplier: 0.192650919},
  {level: 3, multiplier: 0.21573247},
  {level: 3.5, multiplier: 0.236572661},
  {level: 4, multiplier: 0.25572005},
  {level: 4.5, multiplier: 0.273530381},
  {level: 5, multiplier: 0.29024988},
  {level: 5.5, multiplier: 0.306057377},
  {level: 6, multiplier: 0.3210876},
  {level: 6.5, multiplier: 0.335445036},
  {level: 7, multiplier: 0.34921268},
  {level: 7.5, multiplier: 0.362457751},
  {level: 8, multiplier: 0.37523559},
  {level: 8.5, multiplier: 0.387592406},
  {level: 9, multiplier: 0.39956728},
  {level: 9.5, multiplier: 0.411193551},
  {level: 10, multiplier: 0.42250001},
  {level: 10.5, multiplier: 0.432926419},
  {level: 11, multiplier: 0.44310755},
  {level: 11.5, multiplier: 0.4530599578},
  {level: 12, multiplier: 0.46279839},
  {level: 12.5, multiplier: 0.472336083},
  {level: 13, multiplier: 0.48168495},
  {level: 13.5, multiplier: 0.4908558},
  {level: 14, multiplier: 0.49985844},
  {level: 14.5, multiplier: 0.508701765},
  {level: 15, multiplier: 0.51739395},
  {level: 15.5, multiplier: 0.525942511},
  {level: 16, multiplier: 0.53435433},
  {level: 16.5, multiplier: 0.542635767},
  {level: 17, multiplier: 0.55079269},
  {level: 17.5, multiplier: 0.558830576},
  {level: 18, multiplier: 0.56675452},
  {level: 18.5, multiplier: 0.574569153},
  {level: 19, multiplier: 0.58227891},
  {level: 19.5, multiplier: 0.589887917},
  {level: 20, multiplier: 0.59740001},
  {level: 20.5, multiplier: 0.604818814},
  {level: 21, multiplier: 0.61215729},
  {level: 21.5, multiplier: 0.619399365},
  {level: 22, multiplier: 0.62656713},
  {level: 22.5, multiplier: 0.633644533},
  {level: 23, multiplier: 0.64065295},
  {level: 23.5, multiplier: 0.647576426},
  {level: 24, multiplier: 0.65443563},
  {level: 24.5, multiplier: 0.661214806},
  {level: 25, multiplier: 0.667934},
  {level: 25.5, multiplier: 0.674577537},
  {level: 26, multiplier: 0.68116492},
  {level: 26.5, multiplier: 0.687680648},
  {level: 27, multiplier: 0.69414365},
  {level: 27.5, multiplier: 0.700538673},
  {level: 28, multiplier: 0.70688421},
  {level: 28.5, multiplier: 0.713164996},
  {level: 29, multiplier: 0.71939909},
  {level: 29.5, multiplier: 0.725571552},
  {level: 30, multiplier: 0.7317},
  {level: 30.5, multiplier: 0.734741009},
  {level: 31, multiplier: 0.73776948},
  {level: 31.5, multiplier: 0.740785574},
  {level: 32, multiplier: 0.74378943},
  {level: 32.5, multiplier: 0.746781211},
  {level: 33, multiplier: 0.74976104},
  {level: 33.5, multiplier: 0.752729087},
  {level: 34, multiplier: 0.75568551},
  {level: 34.5, multiplier: 0.758630378},
  {level: 35, multiplier: 0.76156384},
  {level: 35.5, multiplier: 0.764486065},
  {level: 36, multiplier: 0.76739717},
  {level: 36.5, multiplier: 0.770297266},
  {level: 37, multiplier: 0.7731865},
  {level: 37.5, multiplier: 0.776064962},
  {level: 38, multiplier: 0.77893275},
  {level: 38.5, multiplier: 0.781790055},
  {level: 39, multiplier: 0.78463697},
  {level: 39.5, multiplier: 0.787473578},
  {level: 40, multiplier: 0.79030001},
];

export const getCP = (pokemon, atk, def, sta, level=20) => {
  const cpm = level === 20 ? 0.59740001 : cpmultiplier.find(o => o.level === level).multiplier;
  return Math.floor((cpm*cpm*(pokemon.baseAttack+atk)*Math.sqrt((pokemon.baseDefense+def))*Math.sqrt((pokemon.baseStamina+sta)))/10);
}

import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"
import { getAttributes } from "../../utils/stats"
import PokemonCard from "./pokemonCard"
import PokemonStats from "./pokemonStats"
import PokemonMoves from "./pokemonMoves"
import PokemonEvolutions from "./pokemonEvolutions"
import PokemonDescription from "./pokemonDescription"
import PokemonCpTable from "./pokemonCpTable"
import PokemonTypeChart from "./pokemonTypeChart"


const PokemonPageTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  const { weaknesses, resistances } = getAttributes([
    post.frontmatter.type1,
    post.frontmatter.type2,
  ])

  const resistsTo = Object.keys(resistances)
  const weakAgainst = Object.keys(weaknesses)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`${post.frontmatter.name} #${post.frontmatter.id} Pokémon GO`}
        description={`What are ${post.frontmatter.name} best moves? How to beat ${post.frontmatter.name}? What are its counters? Discover all you NEED to know about ${post.frontmatter.name}, the ${post.frontmatter.id} Pokémon in Pokémon GO!`}
        slug={location.pathname}
        evolutionevoli={post.frontmatter.evolutionevoli}
      />
      <article>

        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.name} Pokémon GO
          </h1>
        </header>

        <div
          style={{
            textAlign: "center",
          }}
        >
          {post.frontmatter.image && (
            <img
              src={post.frontmatter.image.publicURL}
              alt={`${post.frontmatter.name} #${post.frontmatter.id} Pokémon GO`}
              style={{
                marginTop: 56,
                width: 128,
                height: 128,
              }}
            />
          )}
          <h2 style={{ marginTop: 0 }}>
            #{post.frontmatter.id} - {post.frontmatter.name}
          </h2>
        </div>

        <div id="ezoic-pub-ad-placeholder-104"> </div>


        <PokemonDescription
          pokemon={post.frontmatter}
          resistances={resistsTo}
          weaknesses={weakAgainst}
        />

        <div id="ezoic-pub-ad-placeholder-105"> </div>

        <div style={{ marginBottom: 24 }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />


        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />


        <PokemonStats pokemon={post.frontmatter} />
        <div id="ezoic-pub-ad-placeholder-106"> </div>


        <PokemonMoves pokemon={post.frontmatter} />
        <div id="ezoic-pub-ad-placeholder-107"> </div>

        {post.frontmatter.evolutions && (
          <PokemonEvolutions pokemon={post.frontmatter} />
        )}

        <PokemonTypeChart
          pokemon={post.frontmatter}
          weaknesses={weaknesses}
          resistances={resistances}
        />
        <div id="ezoic-pub-ad-placeholder-108"> </div>

        <hr
          style={{
            marginTop: rhythm(1),
            marginBottom: rhythm(1),
          }}
        />


        <hr
          style={{
            marginTop: rhythm(1),
            marginBottom: rhythm(1),
          }}
        />

        <PokemonCpTable pokemon={post.frontmatter} />
        <div id="ezoic-pub-ad-placeholder-109"> </div>

        <hr
          style={{
            marginTop: rhythm(1),
            marginBottom: rhythm(1),
          }}
        />

        <PokemonCard pokemon={post.frontmatter} />

        <h2>FAQ {post.frontmatter.name} Pokémon GO</h2>

        <div id="ezoic-pub-ad-placeholder-110"> </div>


        <h3>Does {post.frontmatter.name} evolve?</h3>
        {post.frontmatter.evolvesTo ? (
          <p>
            {post.frontmatter.name} can evolve to {post.frontmatter.evolvesTo}{" "}
            for {post.frontmatter.evolvesToCandies}
          </p>
        ) : (
          <p>
            {post.frontmatter.name} does not evolve to another Pokémon in
            Pokemon GO.
          </p>
        )}

        <h3>What Pokemon is number {post.frontmatter.id}?</h3>
        <p>
          {post.frontmatter.name} is the Pokémon number {post.frontmatter.id} in
          the national Pokédex.
        </p>

        <div id="ezoic-pub-ad-placeholder-111"> </div>

        <h3>What type is {post.frontmatter.name}?</h3>
        <p>
          {post.frontmatter.name} is a Pokémon of type {post.frontmatter.type1}{" "}
          {post.frontmatter.type2 && `and ${post.frontmatter.type2}`}.
        </p>

        <h3>Is {post.frontmatter.name} shiny in pokémon go?</h3>
        <p>
          {post.frontmatter.shiny
            ? `Yes, ${post.frontmatter.name} is available as a Shiny Pokémon in PokemonGO`
            : `No, ${post.frontmatter.name} is not available as a Shiny Pokémon in PokemonGO`}
          .
        </p>

        {/*
        <h3>How good is {post.frontmatter.name} Pokemon go?</h3>
        <p>
          Is {post.frontmatter.name} a good Pokemon?
          Is {post.frontmatter.name} good Pokemon go?
          Is {post.frontmatter.name} good for PVP?
        </p>
        */}

        <h3>How do you beat {post.frontmatter.name}?</h3>
        <p>
          How do you counter {post.frontmatter.name}? What beats{" "}
          {post.frontmatter.name} Pokemon? Use {weakAgainst.join(" or ")}{" "}
          Pokemon as they are particularly strong against{" "}
          {post.frontmatter.name}.
        </p>

        <h3>What is {post.frontmatter.name} weakness?</h3>
        <p>
          What is {post.frontmatter.name} weak against? As a{" "}
          {post.frontmatter.type1}{" "}
          {post.frontmatter.type2 && `and ${post.frontmatter.type2}`} Pokémon,{" "}
          {post.frontmatter.name} is weak against {weakAgainst.join(" or ")}.
        </p>

        <h3>What are {post.frontmatter.name} attacks in pokemon go?</h3>
        {post.frontmatter.chargedMoves && post.frontmatter.fastMoves && (
          <p>
            What are pokémon go {post.frontmatter.name} best moveset?{" "}
            {post.frontmatter.name}'s fast moves are{" "}
            {post.frontmatter.fastMoves.join(" or ")} and its charged moves are{" "}
            {post.frontmatter.chargedMoves.join(" or ")}.
          </p>
        )}

        <h3>What is {post.frontmatter.name} max cp?</h3>
        <p>
          {post.frontmatter.name}'s max Combat Power is {post.frontmatter.maxCp}{" "}
          for a 100% IV at level 40.
        </p>

        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />


      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <div id="ezoic-pub-ad-placeholder-101"> </div>
    </Layout>
  )
}

export default PokemonPageTemplate

export const pageQuery = graphql`
  query PokemonPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        id
        name
        form
        type1
        type2
        chargedMoves
        fastMoves
        maxCp
        baseAttack
        baseDefense
        baseStamina
        evolutionevoli
        shiny {
          egg
          evolution
          raid
          research
          wild
        }
        image {
          publicURL
        }
      }
    }
  }
`

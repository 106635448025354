import React from "react"

export default ({ pokemon}) => (
pokemon.evolutions && (<>
    <h2>{pokemon.name} evolution(s)</h2>
    <p>
      {pokemon.evolutions.map(evolution => <div key={evolution.pokemon_name}>{evolution.candy_required} - {evolution.form} - {evolution.pokemon_id} - {evolution.pokemon_name}</div>)}
      </p>
  </>)
)

import React from "react"
import styled from "styled-components"
import { colors } from "../../utils/stats"

const getValues = raw => {
  switch (raw) {
    case 2:
      return 100 * 0.625 * 0.625
    case 1:
      return 100 * 0.625
    case -1:
      return 100 * 1.6
    case -2:
      return 100 * 1.6 * 1.6
    default:
      return 1
  }
}

const Type = styled.span`
  ${props => props.spacing && "margin-right: 4px;"};
  background-color: ${props => props.color};
  border-radius: 8px;
  color: #ffffff;
  font-weight: bold;
  padding: 0 8px;
  text-transform: uppercase;
`

const WeakRes = ({ name, weakres, type }) => (
  <>
    <h4 style={{ marginTop: 0 }}>
      {name} {type}
    </h4>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {weakres.map(t => (
        <div key={t}>
          <Type color={colors[t]} spacing="true">
            {t}
          </Type>
        </div>
      ))}
    </div>
  </>
)

const TypeChart = ({ pokemon, weaknesses, resistances }) => {
  const weakdouble = Object.keys(weaknesses).filter(w => weaknesses[w] === -2)
  const weaksimple = Object.keys(weaknesses).filter(w => weaknesses[w] === -1)
  const neutral = Object.keys(colors).filter(
    p =>
      !Object.keys(weaknesses).includes(p) &&
      !Object.keys(resistances).includes(p)
  )
  const ressimple = Object.keys(resistances).filter(w => resistances[w] === 1)
  const resdouble = Object.keys(resistances).filter(w => resistances[w] === 2)
  return (
    <div>
      <h3>{pokemon.name} type chart</h3>

      {weakdouble.length ? (
        <WeakRes
          name={pokemon.name}
          weakres={weakdouble}
          type="double weakness"
        />
      ) : null}
      {weaksimple.length ? (
        <WeakRes name={pokemon.name} weakres={weaksimple} type="weakness" />
      ) : null}
      {neutral.length ? (
        <WeakRes
          name={pokemon.name}
          weakres={neutral}
          type="takes normal damages from"
        />
      ) : null}
      {ressimple.length ? (
        <WeakRes name={pokemon.name} weakres={ressimple} type="resistance" />
      ) : null}
      {resdouble.length ? (
        <WeakRes
          name={pokemon.name}
          weakres={resdouble}
          type="double resistance"
        />
      ) : null}
    </div>
  )
}

export default ({ pokemon, weaknesses, resistances }) => (
  <>
    <h2>{pokemon.name} type resistance and weakness</h2>
    <h3>{pokemon.name} weaknesses</h3>
    <div>
      {Object.keys(weaknesses).map(wk => (
        <div key={wk}>
          <b>{pokemon.name}</b> takes <b>{getValues(weaknesses[wk])}%</b> of{" "}
          <Type color={colors[wk.toLowerCase()]}>{wk}</Type> type attacks.
        </div>
      ))}
    </div>
    <h3>{pokemon.name} resistances</h3>
    <div>
      {Object.keys(resistances).map(rs => (
        <div key={rs}>
          <b>{pokemon.name}</b> takes <b>{getValues(resistances[rs])}%</b> of{" "}
          <span
            style={{
              textTransform: "uppercase",
              backgroundColor: colors[rs.toLowerCase()],
              color: "#ffffff",
              fontWeight: "bold",
              borderRadius: 8,
              padding: "0 8px",
            }}
          >
            {rs}
          </span>{" "}
          type attacks.
        </div>
      ))}
    </div>

    <TypeChart
      pokemon={pokemon}
      weaknesses={weaknesses}
      resistances={resistances}
    />
  </>
)

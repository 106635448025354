import React from "react"
const MAX_CP = 4431
const MAX_STA = 510
const MAX_ATK = 350
const MAX_DEF = 396

const StatBar = ({ width = "100%", value, maxValue, color }) => (
  <div
    style={{
      backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.4)`,
      borderRadius: 4,
      width,
    }}
  >
    <div
      style={{
        borderRadius: 4,
        backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`,
        width: `${(value / maxValue) * 100}%`,
        textAlign: "right",
        display: "inline-block",
      }}
    >
      <span style={{ color: "#ffffff", fontWeight: "bold" }}>{value}</span>
    </div>
    <div
      style={{
        textAlign: "right",
        width: `${100 - (value / maxValue) * 100}%`,
        display: "inline-block",
      }}
    >
      <span style={{ color: "#ffffff", fontWeight: "bold" }}>/{maxValue}</span>
    </div>
  </div>
)

export default ({ pokemon }) => (
  <>
    <h2>{pokemon.name} Stats</h2>

    <div>
      <span>Max CP</span>
      <StatBar color={[77, 105, 119]} value={pokemon.maxCp} maxValue={MAX_CP} />
    </div>
    <div>
      <span>HP</span>
      <StatBar
        color={[115, 217, 99]}
        value={pokemon.baseStamina}
        maxValue={MAX_STA}
      />
    </div>
    <div>
      <span>Attack</span>
      <StatBar
        color={[255, 71, 71]}
        value={pokemon.baseAttack}
        maxValue={MAX_ATK}
      />
    </div>
    <div>
      <span>Defense</span>
      <StatBar
        color={[0, 179, 250]}
        value={pokemon.baseDefense}
        maxValue={MAX_DEF}
      />
    </div>
  </>
)

import React from "react"
import { colors } from "../../utils/stats"

const getGeneration = id => {
  if (id <= 151) return "first"
  else if (id <= 251) return "second"
  else if (id <= 386) return "third"
  else if (id <= 493) return "fourth"
  else if (id <= 649) return "fifth"
  else if (id <= 721) return "sixth"
  else if (id <= 809) return "seventh"
  else return "eight"
}

const getType = type => (
  <span
    style={{
      marginLeft: 4,
      textTransform: "uppercase",
      backgroundColor: colors[type.toLowerCase()],
      color: "#ffffff",
      fontWeight: "bold",
      borderRadius: 8,
      padding: "0 8px",
    }}
  >
    {type}
  </span>
)

export default ({ pokemon, weaknesses, resistances }) => (
  <>
    <h2>{pokemon.name} description</h2>
    <p>
      {pokemon.name} is a {getGeneration(pokemon.id)} generation Pokémon and the
      number {pokemon.id} in the National Pokédex. It has a total of{" "}
      {pokemon.fastMoves.length + pokemon.chargedMoves.length} possible moves,{" "}
      {pokemon.fastMoves.length} of which are fast moves and{" "}
      {pokemon.chargedMoves.length} are charged moves.
    </p>


    <p>
      {pokemon.name} has {pokemon.type2 ? "2 types" : "1 type"}:{" "}
      {getType(pokemon.type1)}
      {pokemon.type2 ? ' and ' : ""}
      {pokemon.type2 ? getType(pokemon.type2) : ""}, it makes it
      vulnerable to
      {weaknesses.map(w => (
        <span
          key={w}
          style={{
            marginLeft: 4,
            textTransform: "uppercase",
            backgroundColor: colors[w.toLowerCase()],
            color: "#ffffff",
            fontWeight: "bold",
            borderRadius: 8,
            padding: "0 8px",
          }}
        >
          {w}
        </span>
      ))}{" "}
      and resistant to
      {resistances.map(w => (
        <span
          key={w}
          style={{
            marginLeft: 4,
            textTransform: "uppercase",
            backgroundColor: colors[w.toLowerCase()],
            color: "#ffffff",
            fontWeight: "bold",
            borderRadius: 8,
            padding: "0 8px",
          }}
        >
          {w}
        </span>
      ))}
      .
    </p>
  </>
)
